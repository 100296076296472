<template>
    <BootstrapApp :user="user">
        <Content
            :ports="ports"
            :roms="roms"
            :games="games"
            :blogs="blogs"
            :systems="systems" />
    </BootstrapApp>
</template>
<script>
    import BootstrapApp from '../../BootstrapApp';
    import Content from './Content';

    export default {
        name: 'App',
        props: {
            user: {
                type: [Object],
                required: false
            },
            systems: {
                type: [Array],
                required: false
            },
            roms: {
                type: [Array],
                required: false
            },
            games: {
                type: [Array],
                required: false
            },
            ports: {
                type: [Array],
                required: false
            },
            blogs: {
                type: [Array],
                required: false
            },
        },
        components: {
            BootstrapApp,
            Content
        },
    }

</script>


import Assemble from './Assemble.vue'
import vuetify from '../../vuetify/plugin.js'
import { createApp } from 'vue'

const app = createApp()

app.use(vuetify)

app.component('homepage', Assemble)

app.mount('#vuemount')

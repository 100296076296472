<template>
    <div>
        <div class="header">
            <div class="app-width">
                <div class="notice d-flex align-start">
                    <v-card>
                        <v-card-title>
                            <div class="md-title">Video Games Preservation</div>
                        </v-card-title>
                        <v-card-text class="md-layout">
                            <p class="md-layout-item ">In a time of ageing hardware and forgotten games, TRH wants to provide curated retro experiences to everyone. Play retro today on The Retro Hub! 🕹👾</p>

                            <v-icon class="md-layout-item md-size-10 mt-4"><GamepadIcon/></v-icon>

                            <!-- <Test></Test> -->

<!--                             <v-img
                              :width="327"
                              aspect-ratio="16/9"
                              cover
                              src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
                            ></v-img> -->
                            <!-- <GamepadIcon/> -->
                        </v-card-text>
                        <!-- <v-card-actions> -->
                            <!-- <v-btn class="grow prim md-layout">Play Now</v-btn> -->
                            <!-- <v-btn class="secon">Browse</v-btn> -->
                        <!-- </v-card-actions> -->
                    </v-card>
                </div>
            </div>
        </div>
        <div class="app-width">
            <div class="block">

                <div class="content-margin mb-2">
                    <h2 class="mb-2">Blog Latest</h2>

                    <div class="mb-7">
                        <v-container fluid>
                            <v-row>

                                <v-col
                                    style="padding: 6px;"

                                    v-for="blog in blogs"
                                    :key="blog"

                                    lg="3"
                                    md="3"
                                    sm="4"
                                    cols="6"



                                    >

                                    <!-- <v-hover v-slot="{ isHovering, props }"> -->



                                        <v-card

                                            style="cursor: pointer;"
                                            class="game-card"
                                            :href="'/blog/' + blog.slug"

                                            >

                                            <v-img
                                                class="text-white align-end"
                                                :src="blog.image ?? '/provider/common/images/TRH-Logo.jpeg'"
                                                :alt="blog.image"

                                                cover


                                            >


                                            </v-img>
                                                <!-- <v-card-title class="py-3" style="width: 100%; white-space: pre-wrap; word-break: break-word; position: absolute; bottom: 0px; left: 0px;     background-color: rgb(0 0 0 / 66%); line-height: 25px;">{{ blog.title }}</v-card-title> -->

                                                <v-card-title class="my-2">{{ blog.title }}</v-card-title>
                                                <v-card-subtitle>{{ blog.subtitle }}</v-card-subtitle>

                                                <v-card-actions>
                                                      <v-list-item class="w-100">
                                                        <template v-slot:prepend>
                                                          <v-avatar
                                                            color="grey-darken-3"
                                                            size="x-small"
                                                          ><span style="font-size: 13px;">JP</span></v-avatar>
                                                        </template>

                                                        <!-- <v-list-item-title>2023</v-list-item-title> -->

                                                        <v-chip class="float-end">Ensemble Studios</v-chip>

                                                   <!--      <template v-slot:append>
                                                          <div class="justify-self-end">

                                                            <v-icon class="me-1" icon="mdi-share-variant"></v-icon>
                                                            <span class="subheading">45</span>
                                                          </div>
                                                        </template> -->

                                                      </v-list-item>
                                                    </v-card-actions>
                                                <!-- <v-card-title class="text-subtitle-1 pb-1 pt-3">{{ blog.title }}</v-card-title> -->



                                            <!-- <v-card-subtitle class="mb-3"> -->

                                                <!-- {{ game.system.name }} -->
                                            <!-- </v-card-subtitle> -->

                                            <!-- <v-card-text class="text--primary pb-2 pt-2">
                                                <div>{{ game.system.name }}</div>
                                            </v-card-text> -->


                                        </v-card>

                                    <!-- </v-hover> -->

                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </div>


                <div class="content-margin mb-2">
                    <h2 class="mb-2">Game Wiki</h2>

                    <div class="mb-7">
                        <v-container fluid>
                            <v-row>

                                <v-col
                                    style="padding: 6px;"

                                    v-for="game in games"
                                    :key="game.id"

                                    md="3"
                                    sm="3"
                                    cols="6"
                                    class="col-lg-20"


                                    >

                                    <!-- <v-hover v-slot="{ isHovering, props }"> -->



                                        <v-card

                                            style="cursor: pointer;"
                                            class="game-card"
                                            :href="'/games/' + game.system.slug + '/' + game.slug"
                                            >

                                            <v-img
                                                class="text-white align-end"
                                                :src="game.image ?? game.system.library_image ?? '/provider/common/images/TRH-Logo.jpeg'"
                                                :alt="game.image"

                                            >


                                                <!-- <v-card
                                                  v-bind="props"
                                                  :color="isHovering ? 'primary' : undefined"
                                                  title="Hover over me"
                                                  text="..."
                                                ></v-card> -->
                                      <!--           <v-fade-transition v-bind="props" v-if="isHovering">
                                                    <v-img
                                                        src="/provider/common/images/TRH-Logo.jpeg"
                                                        aspect-ratio="1"
                                                        cover
                                                    ></v-img>
                                                </v-fade-transition>
 -->


                                            </v-img>
                                                <!-- <v-card-title style="width: 100%; white-space: pre-wrap; word-break: break-word; position: absolute; bottom: 0px; left: 0px;     background-color: #00000078;">Metal Gear Solid</v-card-title> -->

                                                <v-card-title class="text-subtitle-1 pb-1 pt-3">{{ game.name }}</v-card-title>




                                            <!--  -->

                                            <v-card-subtitle class="mb-3">

                                                {{ game.system.name }}
                                            </v-card-subtitle>

                                            <!-- <v-card-text class="text--primary pb-2 pt-2">
                                                <div>{{ game.system.name }}</div>
                                            </v-card-text> -->


                                        </v-card>

                                    <!-- </v-hover> -->

                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </div>




                <div class="content-margin mb-2">
                    <h2 class="mb-2">PC Ports</h2>

                    <div class="">
                        <v-container fluid>
                            <v-row>

                                <v-col
                                    style="padding: 6px;"

                                    md="3"
                                    sm="3"
                                    cols="6"
                                    class="col-lg-20"


                                    v-for="(item, key) in ports"
                                    :key="key"
                                    >

                                    <v-hover v-slot="{ hover }">

                                        <v-card


                                            style="cursor: pointer;"
                                            :href="'/ports/' + item.slug"
                                            >

                                            <v-img
                                                class="text-white align-end"
                                                aspect-ratio="0.7"
                                                :src=" item.image ? item.image : '/provider/common/images/homebrew-placeholder.jpeg'"
                                                cover
                                            >
                                                <v-fade-transition>
                                                    <v-img
                                                        v-if="hover && item.screenshot"
                                                        aspect-ratio="0.7"
                                                        :src="item.screenshot"
                                                        cover
                                                    ></v-img>
                                                </v-fade-transition>

                                                <v-card-title style="width: 100%; white-space: pre-wrap; word-break: break-word; position: absolute; bottom: 0px; left: 0px;">{{ item.name }}</v-card-title>
                                            </v-img>


                                            <v-card-subtitle class="pb-0 pt-2">
                                                <!-- 1989 -->
                                                {{ item.name }}
                                            </v-card-subtitle>
                                            <v-card-subtitle class="pb-0 pt-2">

                                                {{ item.released }}
                                            </v-card-subtitle>

                                            <v-card-text class="text--primary pb-2 pt-2">
                                                <div>PC, Web Port</div>

                                             <!-- <div>Whitsunday Island, Whitsunday Islands</div> -->
                                            </v-card-text>


                                        </v-card>
                                    </v-hover>

                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </div>



<!--                 <div class="content-margin mb-2">
                    <h2>Emulators</h2>
                </div>
                <div class="temp mb-8" style="">
                    <v-row align="align" no-gutters>
                        <v-col  md="3" sm="4" cols="6" class="col-lg-20" v-bind:key="key" v-for="item, key in emulators">
                            <v-card class="sale-item">
                              <a :href="'emulation/' + item.slug"></a>
                                <v-img aspect-ratio="0.9" :src="item.image" alt="People"/>
                              <v-card-title class="title-height">
                                <div class="md-title">{{ item.title }}</div>
                              </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </div> -->

                <div class="content-margin mb-2">
                    <h2 class="mb-2">Console Games</h2>

                    <div class="roms">
                        <v-container fluid>
                            <v-row>

                                <v-col
                                    style="padding: 6px;"

                                    md="3"
                                    sm="3"
                                    cols="6"
                                    class="col-lg-20"


                                    v-for="(item, key) in roms"
                                    :key="key"
                                    >

                                    <v-hover v-slot="{ hover }">

                                        <v-card


                                            style="cursor: pointer;"
                                            :href="'/emulation/nes/' + item.slug"
                                            >

                                            <v-img
                                                class="text-white align-end"
                                                aspect-ratio="0.7"
                                                :src=" item.image ? item.image : '/provider/common/images/homebrew-placeholder.jpeg'"
                                                cover
                                            >
                                                <v-fade-transition>
                                                    <v-img
                                                        v-if="hover && item.screenshot"
                                                        aspect-ratio="0.7"
                                                        :src="item.screenshot"
                                                        cover
                                                    ></v-img>
                                                </v-fade-transition>

                                                <v-card-title style="width: 100%; white-space: pre-wrap; word-break: break-word; position: absolute; bottom: 0px; left: 0px;">{{ item.name }}</v-card-title>
                                            </v-img>



                                            <v-card-subtitle class="pb-0 pt-2">
                                                1989
                                            </v-card-subtitle>

                                            <v-card-text class="text--primary pb-2">
                                                <div>Platformer</div>

                                             <!-- <div>Whitsunday Island, Whitsunday Islands</div> -->
                                            </v-card-text>


                                        </v-card>
                                    </v-hover>

                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

    import GamepadIcon from 'vue-material-design-icons/Gamepad';
    import SearchIcon from 'vue-material-design-icons/Magnify.vue';
    import Test from './Test.vue';

    export default {
        name: 'Homepage',
        props: {
            roms: {
                type: [Array],
                required: false
            },
            ports: {
                type: [Array],
                required: false
            },
            games: {
                type: [Array],
                required: false
            },
            blogs: {
                type: [Array],
                required: false
            }
        },
        components: {GamepadIcon, SearchIcon, Test},
        data() {
            return {
                emulators: [
                    {
                        image: "/provider/common/images/NES.jpeg",
                        slug: 'nes',
                        title: "Nintendo Entertainment System (NES)",
                        tag:   "nintendo"
                    }
                ]
            }
        }
    }

</script>

<style lang="scss" scoped>

    // .v-size-20 {
    //     min-width: 20%;
    //     max-width: 20%;
    //     flex: 0 1 20%;
    // }



        // @media (min-width: 1264px)
        @media (min-width: 1264px) and (max-width: 1903px) {
            .col-lg-20 {
                min-width: 20%;
                max-width: 20%;
                flex: 0 1 20%;
            }
        }

    .game-card :deep(.v-card-title) {
        line-height: 1rem;
    }

    // .game-card :deep(.v-card-subtitle) {
    //     line-height: 1.5rem;
    // }

    .suggested {
      h2 {
        display: inline-block;
      }

      .md-layout {
        margin-left: 6px;
        margin-right: 6px;
      }

      .md-button {
        text-transform: none;
        border-radius: 16px;
        margin: 3px;
      }
    }

    .block {
      margin-bottom: 25px;
    }

    .header {

        background-color: #cecece;
            background-image: url(/provider/admin/images/icon-controllers2.jpeg?cba76c7…);
            // max-width: 1250px;
            margin: auto;
            width: 100%;
                background-repeat: repeat;


        @media only screen and (max-width: 1480px) {
          max-width: 1480px;
        }



        .notice {
            // display: flex;
            // box-sizing: border-box;
            // height: 100%;
            // min-height: 60vh;  
            //             padding-top: 50px;
            margin-bottom: 50px;
            // justify-content: center;
            // flex-direction: column;

            .v-card {
                width: 320px;
                margin: 4px;
                display: inline-block;
                margin: 50px;

              


                .v-btn {
                  &.prim {
                    background-color: #839591;
                    color: white;
                    font-weight: 600;
                  } 
                  &.secon {
                    border: 1px solid silver;
                  }
                }

            }
        }
    }

    // .temp {


    //     .v-card {

    //         margin-right: 9px;
    //         margin-left: 9px;
    //         margin-bottom: 12px;

    //         border-radius: 2px;

    //         a {
    //             position: absolute;
    //             top: 0px;
    //             bottom: 0px;
    //             left: 0px;
    //             right: 0px;
    //             z-index: 1;
    //         }

    //         // .md-card-media {
    //         //     overflow: hidden;
    //         //     padding-top: 110%; // Dynamic card height. Fakes percentage height of img div

    //         //     img {
    //         //         object-fit: cover;
    //         //         position: absolute;
    //         //         top: 0px;
    //         //         left: 0px;
    //         //         width: 100%;
    //         //         height: 100%;
    //         //     }
    //         // }

    //         .v-card__title {
    //             padding: 6px;
    //             color: #5a6965;

    //             height: 84px;
    //             display: flex;
    //             flex-direction: column;
    //             flex-wrap: nowrap;

    //             &.title-height {
    //                 height: unset;
    //                 display: flex;
    //                 justify-content: center;
    //                 flex-direction: row;

    //                 word-break: break-word;
    //                 // position: absolute; bottom: 0px; left: 0px;
    //             }

    //             .md-title {
    //                 font-size: 22px;
    //                 font-weight: 600;
    //                 letter-spacing: 0;
    //                 line-height: 32px;
    //             }

    //             .md-subhead {
    //                 opacity: .84;
    //                 font-size: 17px;
    //                 font-weight: 500;

    //                 text-overflow: ellipsis;
    //                 overflow: hidden;
    //                 display: -webkit-box;
    //                 -webkit-box-orient: vertical;
    //                 -webkit-line-clamp: 2;
    //             }
    //         }

    //         .md-card-content {
    //             padding: 6px;

    //             .md-chip {
    //                 height: 20px;
    //                 padding: 0px 10px;
    //                 line-height: 18px;
    //                 background-color: #d8e4e1d1;
    //             }

    //             .md-icon {
    //                 margin: unset;

    //                 svg {
    //                     width: 18px;
    //                 }
    //             }
    //         }
            

    //         // .sale-item {
    //         //     // width:
    //         // }
    //     }
    // }



    .feature {
        height: 400px;
        background-image: url(../../../../images/icon-controllers2.jpeg)
    }
</style>

<template>
    <div>
        Test: {{ greeting }}
        <p>{{ computeda }}</p>
    </div>
</template>
<script>

import { computed, ref } from 'vue'


export default {
    name: 'Test',
    setup() {
        console.log('Test')

        const greeting = ref('Hello World!')

        const computeda = computed(() => {
            return (greeting.value.toUpperCase()) + ' JP, Bruh'
        })

        return {
            greeting,
            computeda
        };
    }
}

</script>

<style lang="scss" scoped>
    div {
        color: blue;
    }
</style>

// import Vue from 'vue'
// import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'

// Vue.use(Vuetify)

// const opts = {
//     icons: {
//         iconfont: 'mdiSvg',
//     },
// }

// export default new Vuetify(opts)


import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/lib/styles/main.sass'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'
// import mdi from '@mdi/js';

export default createVuetify({
  components,
  directives,
  // icons: {
  //       defaultSet: "mdi",
  //       sets: {
  //         mdi,
  //         custom: customSVGs,
  //       },
  //     },
})

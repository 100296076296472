<template>
  	<div id="app">
        <v-app>
            <v-app-bar
                :height="50"
                color="#333333"
                style="padding-inline-end: 16px;">
                <div class="app-width">
                    <div class="d-flex">
                        <div class="d-flex flex-grow-1 flex-shrink-1 align-start">
                            <a href="/" style="    text-decoration: none;">
                                <div class="d-flex">

                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                         viewBox="0 0 437.01 437.01" style="enable-background:new 0 0 437.01 437.01; width: 40px; fill: #9eb7b1;" xml:space="preserve">
                                    <g>
                                        <path d="M188.72,178.715h-59.57c-5.522,0-10,4.477-10,10v59.57c0,5.523,4.478,10,10,10h59.57c5.522,0,10-4.477,10-10v-59.57
                                            C198.72,183.192,194.242,178.715,188.72,178.715z M178.72,238.285h-39.57v-39.57h39.57V238.285z"/>
                                        <path d="M307.859,178.715H248.29c-5.522,0-10,4.477-10,10v59.57c0,5.523,4.478,10,10,10h59.569c5.522,0,10-4.477,10-10v-59.57
                                            C317.859,183.192,313.382,178.715,307.859,178.715z M297.859,238.285H258.29v-39.57h39.569V238.285z"/>
                                        <path d="M427.01,238.275h-49.57v-109.13c0-5.523-4.478-10-10-10h-49.58v-49.57c0-5.523-4.478-10-10-10h-178.71
                                            c-5.522,0-10,4.477-10,10v49.57h-49.58c-5.522,0-10,4.477-10,10v109.13H10c-5.522,0-10,4.477-10,10v119.16c0,5.523,4.478,10,10,10
                                            h59.569c5.522,0,10-4.477,10-10v-49.58h39.58v49.58c0,5.523,4.478,10,10,10h59.57c5.522,0,10-4.477,10-10v-49.58h39.57v49.58
                                            c0,5.523,4.478,10,10,10h59.569c5.522,0,10-4.477,10-10v-49.58h39.58v49.58c0,5.523,4.478,10,10,10h59.57c5.522,0,10-4.477,10-10
                                            v-119.16C437.01,242.752,432.532,238.275,427.01,238.275z M417.01,357.435h-39.57v-49.58c0-5.523-4.478-10-10-10h-59.58
                                            c-5.522,0-10,4.477-10,10v49.58H258.29v-49.58c0-5.523-4.478-10-10-10h-59.57c-5.522,0-10,4.477-10,10v49.58h-39.57v-49.58
                                            c0-5.523-4.478-10-10-10h-59.58c-5.522,0-10,4.477-10,10v49.58H20v-99.16h49.569c5.522,0,10-4.477,10-10v-109.13h49.58
                                            c5.522,0,10-4.477,10-10v-49.57h158.71v49.57c0,5.523,4.478,10,10,10h49.58v109.13c0,5.523,4.478,10,10,10h49.57V357.435z"/>
                                            </g>
                                    </svg>
                                    <span class="site-logo text-grey-lighten-2">The Retro Hub</span>

                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </v-app-bar>

            <!-- Sizes your content based upon application components -->
            <v-main>

                <div class="categories content-margin">

                    <div class="app-width">

                        <v-menu location="bottom">
                            <template v-slot:activator="{ props }">
                                <v-btn variant="text" v-bind="props">Emulation</v-btn>
                            </template>

                            <v-list>
                                <v-list-subheader>
                                    Browse emulation by system
                                </v-list-subheader>
                                <v-list-item href="/emulation/nes">
                                    <v-list-item-title>Nintendo Entertainment System (NES)</v-list-item-title>
                                </v-list-item>
                                <v-list-item href="/emulation/ps1">
                                    <v-list-item-title>Playstation (PS1)</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-menu location="bottom">
                            <template v-slot:activator="{ props }">
                                <v-btn variant="text" v-bind="props">Games</v-btn>
                            </template>

                            <v-list>
                                <v-list-subheader>
                                    Browse games by system
                                </v-list-subheader>
                                <!-- <v-list-group> -->
                                    <v-list-item href="/games/nes">
                                        <v-list-item-title>NES</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item href="/games/ps1">
                                        <v-list-item-title>PS1</v-list-item-title>
                                    </v-list-item>
                                <!-- </v-list-group> -->
                            </v-list>
                        </v-menu>

                        <v-menu location="bottom">
                            <template v-slot:activator="{ props }">
                                <v-btn variant="text" v-bind="props">Consoles</v-btn>
                            </template>
                            <v-list>

                                <v-list-subheader class="text-caption">
                                    Browse consoles
                                </v-list-subheader>

                                <v-list-item>
                                    <v-list-item-title>Playstation</v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>Dreamcast</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>

                <!-- Provides the application the proper gutter -->
                <!-- If using vue-router -->
                <slot></slot>

                <v-card class="mt-15" >
                    <v-footer default padless class="bg-grey-darken-3 flex-column pt-0 pb-0">
                        <v-row justify="center" no-gutters>
                            <v-btn
                                color="white"
                                variant="text"
                                rounded
                                class="my-2"
                              >Contact</v-btn>
                            <v-btn
                                color="white"
                                variant="text"
                                rounded
                                class="my-2"
                              >About</v-btn>
                            <v-btn
                                color="white"
                                variant="text"
                                rounded
                                class="my-2"
                              >Privacy Policy</v-btn>
                            <v-btn
                                color="white"
                                variant="text"
                                rounded
                                class="my-2"
                              >T&Cs</v-btn>
                        </v-row>
                        <v-card flat tile width="100%" class="bg-grey-darken-3 text-center">
                            <v-divider class="text-grey-lighten-1 border-opacity-100"></v-divider>

                            <v-card-text>
                                <v-btn href="https://www.instagram.com/theretrohubofficial" target="_blank" rel="noopener" size="60" density="comfortable" class="text-white" variant="text" rounded="xl">
                                    <v-icon icon="mdi-instagram" size="35"></v-icon>
                                    <v-tooltip activator="parent" location="top">Instagram</v-tooltip>
                                </v-btn>

                                <v-btn href="https://www.patreon.com/theretrohub" target="_blank" rel="noopener" size="60" density="comfortable" class="mx-4" variant="text" rounded="xl">
                                    <v-icon icon="mdi-patreon" size="35"></v-icon>
                                    <v-tooltip activator="parent" location="top">Patreon</v-tooltip>
                                </v-btn>
                            </v-card-text>

                            <v-divider class="text-grey-lighten-1 border-opacity-100"></v-divider>

                            <v-card-text class="white--text">
                                <strong>© The Retro Hub – {{ new Date().getFullYear() }}</strong> 🇬🇧
                            </v-card-text>
                        </v-card>
                    </v-footer>
                </v-card>
            </v-main>
        </v-app>
    </div>
</template>

<script>

  import Menu from 'vue-material-design-icons/Menu.vue';

  import AccountCircle from 'vue-material-design-icons/AccountCircle.vue';
  import TrendingDown from 'vue-material-design-icons/TrendingDown.vue';
  import Home from 'vue-material-design-icons/Home.vue';
  import SearchIcon from 'vue-material-design-icons/Magnify.vue';

  import "./../../sass/vue/globals.scss"; // Import app wide scss


  // const analytics = Analytics({
  //   app: 'The Retro Hub',
  //   version: 1,
  //   plugins: [
  //     googleAnalytics({
  //       trackingId: 'G-89WHHTX4FH'
  //     })
  //   ]
  // })

  // analytics.page();

  export default {
    name: 'BootstrapApp',
    data() {
      return {
        showSnackbar: true,
        user_profile: null,
        host: null,
        nav_search: null,
        nav_search_opt: [],
        nav_search_opt_server:
        [
            {
                id: 1,
                name: 'nintendo'
            },
            {
                id: 2,
                name: 'playstation'
            },
            {
                id: 12,
                name: 'playstation 3'
            },,
            {
                id: 13,
                name: 'playstation 4'
            },
            {
                id: 3,
                name: 'xbox'
            },
            {
                id: 4,
                name: 'atari'
            },
            {
                id: 5,
                name: 'sega'
            }
        ]
      }
    },
    props: {
      user: Object
    },
    computed: {
      checkAuthAndPhoto: function() 
      {
        return (this.checkAuth() && this.user.profile_picture !== null && this.host !== null) ? true : false;
      },   
      checkAuthNoPhoto: function() 
      {
        return (this.checkAuth() && this.user.profile_picture === null) ? true : false;
      },
      userNameCheck: function()
      {
        return (this.checkAuth()) ? this.user.name + "'s" : 'My';
      }
    },
    methods: {
        checkAuth()
        {
            return (typeof this.user !== 'undefined' && this.user !== null);
        },
        getSearchQuery(searchTerm) 
        {

            console.log(searchTerm);

            this.nav_search_opt = new Promise(resolve => {
                      window.setTimeout(() => {
                        if (!searchTerm) {
                          resolve([])
                        } else {
                          const term = searchTerm.toLowerCase()

                          resolve(this.nav_search_opt_server.filter(({ name }) => name.toLowerCase().includes(term)))
                        }
                      }, 500)
                    })

            // this.nav_search_opt =
            // [
            //     {
            //         id: 1,
            //         name: 'nintendo'
            //     },
            //     {
            //         id: 2,
            //         name: 'playstation'
            //     },
            //     {
            //         id: 3,
            //         name: 'xbox'
            //     },
            //     {
            //         id: 4,
            //         name: 'atari'
            //     },
            //     {
            //         id: 5,
            //         name: 'sega'
            //     }
            // ];
        },
        navSearch() 
        {
            window.location.href = '/search?q=' + this.nav_search
        },
        navSearchSelected(q)
        {
            this.nav_search = q.name
            console.log(q);
            // window.location.href = '/search?q=' + q  
        }
    },
    components: {
      Menu,
      AccountCircle,
      TrendingDown,
      Home,
      SearchIcon
    },
    // data: () => ({
    //     menuVisible: false
    //   }),
    created()
    {
      this.host = window.location.origin;
    }
  }

</script>

    <style lang="scss">



        // Autocomplete global styles
        .md-theme-toolbar {

            .nav-search {

                // .md-autocomplete {

                    .md-menu {

                        background-color: white;

                        .md-input
                        {
                            margin-left: 15px;
                        }
                    }
                // }
            }

            .md-menu-content-container 
            {
                background-color: white;
            }

            .md-highlight {
                background-color: #80808045;
            }


        }

        .v-toolbar__content{
            padding: unset !important;
        }

    </style>




  <style lang="scss" scoped>

    // @import '~vuetify/src/styles/styles.sass'

    // @import '~vuetify/lib/components/VBtn/_variables.scss';
    // @import '~vuetify/src/components/VBtn/_variables.scss';


    .v-app-bar {
        padding: 0 16px;
    }

    .v-main {
        background-color: #e0e0e08f;
    }


	.site-logo {
		font-family: PixelSplitter;
		font-size: 25px;
        line-height: 40px;
		// color: #e2e2e2;
		// color: md-get-palette-color(grey, 300);

        // @extend .grey;
		padding-left: 15px;
		// color: md-get-palette-color(red, 200)
	}
  
    .nav-head{
      .logo-cont{
        margin-left: auto;
      }
      p.nav-title{
        margin-right: auto;
        padding-left: 3px;
      }
    }

    .title {
      padding: 15px;
      font-size: 17px;
    }

     // Demo purposes only
    // .md-toolbar.md-theme-default.md-primary {
      // width: 230px;
      // max-width: calc(100vw - 125px);
      // background-color: white !important;
    // }



    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      // text-align: center;
      color: #2c3e50;
      /*padding-top: 30px;*/
      overflow: hidden;
      // height: 100vh;
    }

    .md-app{
    	height: 100vh;
    	overflow: scroll;
    }

    

    // div.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-toolbar {
    //     background-color: white;

    //     .md-highlight {
    //         background-color: #80808045;
    //     }
    // }
    



    // .md-theme-toolbar
    // {
    //     // .md-menu-content-container{
    //         background-color: white; 

    //     // }
    // }


    .categories {
        // background-color: md-get-palette-color(grey, 300);
        border-top: 2px solid #9eb7b1 !important;
        // border-color: #9eb7b1!important;
        background-color: #e0e0e0;

        div.pipe {
            height: 100%;
            width: 1px;
        }
    }

    .v-btn{

        // background-color: unset;
        // border: unset;
        // box-shadow: unset;
        // padding-left: 10px;
        // padding-right: 10px;

        // color: rgba(0,0,0,0.87);
        letter-spacing: 0.3px;
        font-weight: 500;
        // border: 1px solid #8c8c8c;
        // border-radius: 4px;
        // background-color: #e0e0e0;
        // margin-left: 15px;
        // color: #e0e0e0;
        // height: 30px;
        min-width: unset;
        text-transform: uppercase;
        // padding: 0px 5px;
        // margin-top: 4px;

        &:not(.v-btn--round).v-size--default {

            padding: 0px 8px;
        }
    }

    // .md-menu-content-bottom-start{
    //     background-color: green;
    // }



    .md-app-toolbar {
    	// background-color:  #545454;


    	// min-height: 50px;
    	// height: 50px;

    	// @include md-theme-component() {
    	// 	@include md-theme-property(background, primary);
    	// }

    	// background: hsla(180, 1%, 14%, 1);

    	// background: linear-gradient(90deg, hsla(180, 1%, 14%, 1) 1%, hsla(0, 0%, 22%, 1) 100%);

    	// background: -moz-linear-gradient(90deg, hsla(180, 1%, 14%, 1) 1%, hsla(0, 0%, 22%, 1) 100%);

    	// background: -webkit-linear-gradient(90deg, hsla(180, 1%, 14%, 1) 1%, hsla(0, 0%, 22%, 1) 100%);



    	.md-fieldss {

            .md-menu {
                background-color: white;

            }

            // margin-left: -25px;

            display: flex;
            align-items: center;

            padding-top: unset;
            margin-bottom: unset;
            margin-top: unset;
            min-height: 40px;

    		// width: auto;
    		// padding-left: 15px;

    		// margin: unset;
    		// padding: unset;
    		// margin-top: 15px;

    		// margin-top: -2px;

    		// text-transform: none;


    		label{
    			padding-left: 8px;
    		}

    		&::after {display: none}
    		&::before {display: none}

    		// margin-left: -30px;

    		input {
    			// padding-left: 8px;
    			border-radius: 10px;
                padding-left: 30px;
    		}

            .md-menu:nth-child(1)
            {
                margin-left: 25px;
            }

            // .md-menu {

                

            // }

            // .md-autocomplete {

            //     .md-menu {
            //         .md-input {
            //             padding-left: 30px;
            //         }
            //     }
            // }
    	}
        }






    .md-app-content {

    	overflow: hidden;
        min-height: 100vh;
        padding: unset;
        border: unset;
      background-color: #eaeaea;

    	// position: relative;

    	// div.content{
    	// 	position: absolute;
    	// 	width: 100%;
    	// 	top: 0px;
    	// 	bottom: 0px;
    	// }

    	// background-color: #fafafa;



        // overflow: hidden;
        // min-height: 100vh;
    }

    .app-footer {
        margin-top: 25px;
    }

  </style>

<!-- <style>
  .cal-app-drawer {
    background-color: white;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /*padding-top: 30px;*/
    overflow: hidden;
  }
</style>
 -->
